<template>
  <v-card elevation="0" max-width="1010" rounded="xl" class="mx-auto pa-sm-8 pa-4 login width100">
    <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
    <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('login.title') }}</div>
    <div class="gray--text text-center mb-4">{{ $t('login.description') }}</div>
    <div style="max-width: 380px" class="mx-auto">
      <v-form @submit.prevent="login" ref="form" lazy-validation :key="isIdMode">
        <div class="mb-6 mx-auto accent radius10" style="max-width: fit-content; padding: 3px">
          <v-btn
            height="36"
            :class="isIdMode ? 'btn-shadow white' : 'accent'"
            depressed
            class="me-1 px-6 rounded-lg"
            @click="changeMode(true)"
          >
            <span class="text-body-1 font-weight-bold" :class="isIdMode ? 'secondary--text' : 'gray--text'">
              {{ $t('btn.idNumber') }}
            </span>
          </v-btn>
          <v-btn
            height="36"
            :class="!isIdMode ? 'btn-shadow white' : 'accent'"
            depressed
            class="ms-1 px-6 rounded-lg"
            @click="changeMode(false)"
          >
            <span class="text-body-1 font-weight-bold" :class="!isIdMode ? 'secondary--text' : 'gray--text'">
              {{ $t('btn.phoneNumber') }}
            </span>
          </v-btn>
        </div>
        <div class="black--text">{{ isIdMode ? $t('input.idNumber') : $t('input.phone') }}</div>
        <v-text-field
          v-if="isIdMode"
          v-model="id"
          :error-messages="idErrors"
          :placeholder="$t('input.id')"
          outlined
          required
          color="secondary"
          maxlength="10"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="phone"
          :placeholder="$t('input.phone')"
          :error-messages="phoneErrors"
          outlined
          required
          color="secondary"
          maxlength="15"
        >
          <template v-slot:prepend-inner>
            <div class="d-flex align-center link" @click="modal = true" style="min-width: 96px; margin-top: -1px">
              <img v-if="!$vuetify.rtl" width="32" height="24" :src="flagImg(countryCode)" :srcset="flagImgSet(countryCode)" />
              <!-- <v-card flat tiel width="15" height="20">
                <v-icon style="font-size: 16px">mdi-chevron-down</v-icon>
              </v-card> -->
              <div class="ms-1 black--text">
                (<span v-if="!$vuetify.rtl">+</span>
                {{ flagList.find((item) => item.ct == countryCode).cl }}
                <span v-if="$vuetify.rtl">+</span>)
              </div>
              <img
                v-if="$vuetify.rtl"
                class="rounded-circle ms-2 me-1"
                width="24"
                height="24"
                :src="flagImg(countryCode)"
                :srcset="flagImgSet(countryCode)"
              />
            </div>
          </template>
        </v-text-field>
        <div class="black--text">{{ $t('input.password') }}</div>
        <v-text-field
          v-model="password"
          :placeholder="$t('input.password')"
          :error-messages="passwordErrors"
          :type="show ? 'text' : 'password'"
          outlined
          required
          color="secondary"
        >
          <template v-slot:append>
            <img class="link" width="24" height="24" @click="show = !show" :src="getIcon()" />
          </template>
        </v-text-field>
        <router-link class="d-block secondary--text text-body-2 font-weight-bold mt-n3 mb-6 btn-underline" to="/forgot-password">
          {{ $t('btn.forgotPass') }}
        </router-link>
        <v-btn height="64" depressed type="submit" block large class="primary radius10">
          <span class="text-body-1 font-weight-bold">{{ $t('btn.continue') }}</span>
        </v-btn>
        <div class="d-flex align-center mt-5 mb-10">
          <span class="gray--text text-body-2 pe-2">{{ $t('login.notAccount') }}</span>
          <router-link class="secondary--text text-body-2 font-weight-bold btn-underline" to="/sign-up">
            {{ $t('btn.registration') }}
          </router-link>
        </div>
      </v-form>
    </div>
    <v-dialog max-width="340" v-model="modal">
      <v-card flat class="pa-3" height="380">
        <v-text-field
          @keyup="searchCountry"
          class="mb-2"
          v-model="country"
          hide-details
          outlined
          required
          color="secondary"
          maxlength="15"
        ></v-text-field>
        <v-card tile flat height="300" class="view-without-scroll pa-2">
          <div v-for="item in requestsFlag" :key="item.ct" class="my-3">
            <div
              :class="accessCountry.find((i) => i.code == item.ct) ? 'd-flex ' : 'd-none'"
              class="link"
              @click="(countryCode = item.ct), (modal = false)"
            >
              <img max-width="32" :src="flagImg(item.ct)" :srcset="flagImgSet(item.ct)" />
              <div class="clip">
                <span class="ms-2">{{ item[lang] }}</span>
                <span class="ms-2">(<span v-if="!$vuetify.rtl">+</span>{{ item.cl }}<span v-if="$vuetify.rtl">+</span>)</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import json from '@/codes.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      isIdMode: true,
      flagList: json,
      country: '',
      requestsFlag: json,
      countryCode: 'sa',
      id: '',
      phone: '',
      password: '',
      show: false,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPhoneCountry');
  },
  methods: {
    notifi(btn) {
      if (btn == 'exceeded') {
        this.$notify({
          title: this.$t('alert.exceededTitle'),
          message: this.$t('alert.exceededDescription'),
          type: 'error',
          icon: 'mdi-alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'notFound') {
        this.$notify({
          title: '',
          message: this.$t('alert.notFoundCustomer'),
          type: 'warning',
          icon: 'mdi-alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeMode(isId) {
      this.isIdMode = isId;
    },
    searchCountry() {
      const value = this.country.toString().toLowerCase();
      this.requestsFlag = this.flagList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    getIcon() {
      return require(`@/assets/icon/${this.show ? 'passShow' : 'passHide'}.svg`);
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
    async login() {
      this.error = [];
      const data = new Object();
      if (this.isIdMode) {
        data.id_type = 'national_id';
        data.id_number = this.id;
      } else {
        data.phone_code = this.countryCode.toUpperCase();
        data.phone_number = this.phone;
      }
      data.password = this.password;
      await this.$store
        .dispatch(this.isIdMode ? 'signInId' : 'signIn', data)
        .then((res) => {
          sessionStorage.setItem('sid', res.data.sid);
          this.$router.push(`/sign-in/confirm${this.$route.query.from ? `?from=${this.$route.query.from}` : ''}`);
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((item) => item == 'request__unauthorized') ? this.$router.push('/sign-up') : '';
          this.error.find((item) => item == 'action__limit_exceeded') ? this.notifi('exceeded') : '';
          this.error.find((item) => item == 'customer__not_found') ? this.notifi('notFound') : '';
        });
    },
  },
  watch: {
    modal() {
      if (!this.modal) {
        this.requestsFlag = this.flagList;
        this.country = '';
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.langValue;
    },
    accessCountry() {
      return this.$store.getters.phoneCountry;
    },
    idErrors() {
      const errors = [];
      this.error.find((item) => item == 'id_number__required') && errors.push(this.$t('inputError.idRequired'));
      this.error.find((item) => item == 'id_number__invalid') && errors.push(this.$t('inputError.idInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t('inputError.badCredLoginId'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone__required') && errors.push(this.$t('inputError.phoneRequired'));
      this.error.find((item) => item == 'phone__invalid') && errors.push(this.$t('inputError.phoneInvalid'));
      this.error.find((item) => item == 'phone_number__required') && errors.push(this.$t('inputError.phoneRequired'));
      this.error.find((item) => item == 'phone_number__invalid') && errors.push(this.$t('inputError.phoneInvalid'));
      this.error.find((item) => item == 'country__not_allowed') && errors.push(this.$t('inputError.phoneAllowed'));
      this.error.find((item) => item == 'action__not_allowed') && errors.push('inputError.actionNotAllowed');
      this.error.find((item) => item == 'bad_credentials') && errors.push('inputError.badCredLogin');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push(this.$t('inputError.passwordRequired'));
      this.error.find((item) => item == 'password__invalid') && errors.push(this.$t('inputError.passwordInvalid'));
      this.error.find((item) => item == 'bad_credentials') &&
        errors.push(this.isIdMode ? this.$t('inputError.badCredLoginId') : this.$t('inputError.badCredLogin'));
      return errors;
    },
  },
};
</script>
